import { DOCUMENT, isPlatformServer } from "@angular/common";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { LoggerService } from "@services/logger-service.service";

@Injectable()
export class CookieService {

  COOKIE_STRING: string;

  constructor(private logger: LoggerService,
              @Inject(DOCUMENT) private document: any,
              @Inject(PLATFORM_ID) private platformId: Object) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  exists(name: string): boolean {
    name = encodeURIComponent(name);
    const regExp: RegExp = this.getCookieRegExp(name);
    return regExp.test(this.getCookiesString());
  }

  get(name: string): string {
    if (this.exists(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray = regExp.exec(this.getCookiesString());

      const value: string = decodeURIComponent(result[1]);

      this.logger.debug("Cookie '" + name + "' was read: ", value);
      return value;
    } else {
      return "";
    }
  }

  getJson(name: string): any {
    if (this.exists(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray = regExp.exec(this.getCookiesString());

      const value = JSON.parse(decodeURIComponent(result[1]));
      this.logger.debug("Cookie '" + name + "' was read: ", value);
      return value;
    } else {
      return "";
    }
  }

  // expires - numbers of days your cookie will live
  set(name: string,
      value: string,
      expires?: number | Date,
      path?: string,
      domain?: string,
      secure?: boolean): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    let cookieString: string = encodeURIComponent(name) + "=" + encodeURIComponent(value) + ";";

    if (expires) {
      if (typeof expires === "number") {
        const dateExpires: Date = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);

        cookieString += "expires=" + dateExpires.toUTCString() + ";";
      } else {
        cookieString += "expires=" + expires.toUTCString() + ";";
      }
    }

    if (path) {
      cookieString += "path=" + path + ";";
    }

    if (domain) {
      cookieString += "domain=" + domain + ";";
    }

    if (secure) {
      cookieString += "secure;";
    }

    this.logger.debug("Setting cookie...", cookieString);
    this.document.cookie = cookieString;
  }

  delete(name: string, path?: string, domain?: string): void {
    this.set(name, "", -1, path, domain);
  }

  private getCookiesString(): string {
    return isPlatformServer(this.platformId) ? this.COOKIE_STRING : this.document.cookie;
  }

  private getCookieRegExp(name: string): RegExp {
    const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/ig, "\\$1");

    return new RegExp("(?:^" + escapedName + "|;\\s*" + escapedName + ")=(.*?)(?:;|$)", "g");
  }

}
