import {Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {LoggerService} from "@services/logger-service.service";
import {BehaviorSubject, Observable} from "rxjs";
import {Switcher} from "@core/models/switcher.enum";
import {CookieService} from "@services/cookie.service";

@Injectable()
export class SwitcherDarkLightService {
  private renderer: Renderer2;

  private switcherSource: BehaviorSubject<Switcher> = new BehaviorSubject(Switcher.LIGHT);
  public switcher$: Observable<Switcher> = this.switcherSource.asObservable();

  constructor(private logger: LoggerService,
              private coockieService: CookieService,
              rendererFactory: RendererFactory2) {
    this.logger.debug(this.constructor.name + " is created.");
    this.renderer = rendererFactory.createRenderer(null, null);
    this.getSwitcher();
  }

  getSwitcher() {
    const switcherFromCoockie: Switcher = this.coockieService.get("tp-switcher-dl") as Switcher;
    if (switcherFromCoockie) {
      this.setClass(switcherFromCoockie);
      this.switcherSource.next(switcherFromCoockie);
    }
  }

  switch() {
    const setSwitcher: Switcher = this.switcherSource.getValue() === Switcher.DARK ? Switcher.LIGHT : Switcher.DARK;
    this.setClass(setSwitcher);
    this.switcherSource.next(setSwitcher);
    this.coockieService.set("tp-switcher-dl", setSwitcher);
  }

  setClass(switcher: Switcher) {
    if (switcher === Switcher.DARK) {
      this.renderer.addClass(document.body, "dark");
    } else {
      this.renderer.removeClass(document.body, "dark");
    }
  }
}
